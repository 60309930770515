import Helmet from 'preact-helmet';
import RightPanel from '../../components/right';

const About = () => (
	<div>
		<Helmet title="O nás, kdo jsme?" />
		<div class="first_container">
			<div class="story">
				<div class="breadcrumb">
					<h1>O nás, kdo jsme?</h1>
					<ul id="nav">
						<li><a href="/" >Home</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li>O nás, kdo jsme?</li>
					</ul>
				</div>
				<p>Jsme společnost, která se zabývá průmyslovou a reklamní výrobou, resp.
				průmyslovým balením a plněním zboží (hmot) do sáčků nebo lahviček.</p>
				<p>Neustále procházíme kontinuální inovací a novými nápady v oboru
				balení a plnění zboží se snažíme vyhovět né jen standardům v oboru
				balení výrobků, ale i vysokým nárokům velkých koncernů a evropských	směrnic.</p>
				<h3>Příznivá cenová hladina</h3>
				<p>Ceny výrobků jsou konkurenceschopné, a to vždy při garanci maximální
				kvality a nabídce přidané hodnoty v podobě doplňkových služeb. Poměr
				cena-kvalita vyznívá ve prospěch BESTPACK ve většině výběrových	řízení.</p>
				<h3>Finanční stabilita společnosti</h3>
				<p>Dokážeme obchodním partnerům garantovat naprosto spolehlivou platební
				morálku. I v době, kdy většina výrobních firem bojuje s hospodářskou krizí, nemáme žádné závazky po splatnosti.</p>
				<h3>Kvalita</h3>
				<p>Vysoká kvalita a jakost výrobků i doplňkových služeb je zákazníky
				dlouhodobě hodnocena jako silná stránka firmy. K vybraným produktům jsou
				k dispozici certifikáty mezinárodních výrobců obalových strojů.
				Samostatnou kapitolou kvality produktů je pak splňování podmínek tak
				náročných segmentů jako je potravinářský průmysl či výroba hygienických potřeb.</p>
				<h3>Komplexnost služeb</h3>
				<p>Kvalitu obchodního partnera dnes již neurčuje jen jakost samotného
				výrobku. Zákazníci požadují také kvalitu servisu. BESTPACK nabízí svým
				zákazníkům přidanou hodnotu v oblasti komplexních logistických služeb
				(variabilita přepravních řešení, konsignační sklady, balení na míru),
				špičkových možnostech potisku (profesionální potisk na flexografických
				strojích, předtisková příprava, blízké grafické studio) a v oblasti technologického zázemí.</p>
				<h3>Technologie a vlastní vývoj</h3>
				<p>Personální zázemí společnosti tvoří mimo jiné tým zkušených
				konstruktérů a technologů. Dokážeme tak zajistit svým zákazníkům
				služby v podobě technického a aplikačního poradenství přímo ve
				výrobním závodě. Tým vývojových pracovníků pak pomáhá společnosti
			pružně reagovat na nové trendy v oblasti obalového průmyslu.</p>
				<h3>Moderní zázemí</h3>
				<p>Využíváním technologie, splňováním podmínek HACCP a moderním
				zařízením BESTPACKu poskytujeme svým zákazníkům nespornou konkurenční výhodu.</p>
				<h3>Inovace a investice</h3>
				<p>Prioritou firmy je neustálý rozvoj a zdokonalování se. Každým rokem
				jsou nemalé prostředky investovány do modernějšího a výkonnějšího
				strojního vybavení. Velká pozornost je také věnována novým možnostem úprav výrobků a potisku.</p>
				<h3>Široké produktové portfolio</h3>
				<p>Výrobní sortiment představuje mnoho fólií a finálních produktů
				z fólií. Na přání zákazníků je výrobní sortiment průběžně
				rozšiřován o nové rozměry, produkty se specifickými vlastnostmi, produkty
				s různou aditivací a probarvením, povrchovými úpravami i variantami	potisku.</p>
			</div>
			<RightPanel />
			<div class="clear" />
		</div>
	</div>
);
export default About;